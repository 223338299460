body {
  background-color: #000000;
  background-image: radial-gradient(teal 10%, transparent 10%);
  background-size: 12px 12px;
  background-position: center;
}

div, h2, p, a {
  font-family: Oxanium;
  color: #62d1de;
}

input, textarea, td {
  color: #cbcdd7 !important;
}

label, button, .MuiButton-outlined, .MuiSelect-select {
  color: #62d1de !important;
}

table {
  background-color: #333333;
}

.events-title {
  margin-left: .7em;
  font-size: 2rem;
  font-weight: 400;
  flex-basis: 0;
  flex-grow: 1; 
}

.alert {
  padding: .4em 1.2em 0em 1.2em;
}

.MuiAlert-message {
  color: #358790 !important;
}

.account-menu-box {
  margin-right: .75em;
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.account-menu { 
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #312f2f;
  border-radius: 2px;
}

.event-page {
  padding: 1em;
  border-radius: 10px !important;
  background-color: #222222 !important;
}

.event-details-img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.form-error {
  font-size: .8rem;
  margin-top: .3em;
  color: #893a3a;
}

.new-event-box {
  margin: .75em 1em;
  background-color: #444444 !important;
  border-radius: 10px !important;
}

.new-event-card {
  padding: 1em 1em .5em 1em;
}

.MuiPopover-paper {
  border-radius: 15px !important;
}

.blinking {
  animation: blink 2s infinite;
  padding: 0.5em .27em 0.5em .27em;
  color: #cbcdd7 !important;
}

@media screen and (max-width: 480px) {
  label {
    font-size: .95rem !important;
  }

  table {
    word-break: break-word;
  }

  td {
    min-width: 8.2em;
    font-size: .7rem !important;
  }
  
  .events-title {
    margin: 0em;
    font-size: 1.55rem;
    align-self: end;
  }

  .alert {
    padding: .2em .8em 0em .8em;
  }

  .event-page {
    padding: .4em;
  }

  .event-details-title {
    font-size: 1.2rem !important;
  }

  .event-details-img {
    max-height: 12em;
    width: 100%;
    object-fit: cover !important;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

::-webkit-scrollbar {
  width: .5em;
}
  
::-webkit-scrollbar-track {
  background-color: transparent;
  margin-top: .45em;
}
  
::-webkit-scrollbar-thumb {
  background: #4398a1;
}



  