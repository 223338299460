.google-map {
  width: 100%;
  height: 90vh;
  margin-top: .46em;
}

.pin {
  display: flex;
  align-items: center;
}

.pin-icon {
	position: relative;
  font-size: .8rem;
  padding: .4em .59em;
  transform: translate(-50%, -100%);
	background: #164df2;
  color: aliceblue;
	border-radius: .4em;
  border: #b4b4ff 1px solid;
}

.pin-icon:after {
	content: '';
	position: absolute;
	bottom: .06em;
	left: 50%;
	width: 0;
	height: 0;
	border: .57em solid transparent;
	border-top-color: #164df2;
	border-bottom: 0;
	margin-left: -.5em;
	margin-bottom: -.5em;
}

.pin-icon-grey {
	position: relative;
  font-size: .8rem;
  padding: .4em .59em;
  transform: translate(-50%, -50%);
	background: grey;
  color: aliceblue;
	border-radius: .4em;
}

.pin-icon-grey:after {
	content: '';
	position: absolute;
	bottom: .06em;
	left: 50%;
	width: 0;
	height: 0;
	border: .57em solid transparent;
	border-top-color: grey;
	border-bottom: 0;
	margin-left: -.5em;
	margin-bottom: -.5em;
}

.pin-box {
  width: 240px;
  background-color: #111111 !important;
  border: 4px solid #62d1de;
  border-radius: 15px !important;
}

.pin-img {
  object-fit: contain !important;
  margin-bottom: .1em;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pin-date {
  color: #ffffff !important;
  font-size: 0.75rem;
}

.pin-countdown {
  color: #ffffff !important;
  font-size: 0.75rem;
}

.pin-name {
  font-size: 0.95rem;
  font-weight: 500;
  margin: .2em 0em;
}

.pin-city, .pin-types {
  color: #6B7280;
  font-size: 0.75rem;
}

.view-button {
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  color: #62d1de;
}

@media screen and (max-width: 480px) {
  .google-map {
    height: 100%;
    margin-top: 0em;
  }
}


  