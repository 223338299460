.event-card-box {
  min-height: 14.5em;
  margin: 0em 1em 1em 1em;
  border-radius: 25px !important;
  background-color: #111111 !important;
  border: 2px solid #62d1de;
}

.event-img {
  height: 100%;
  width: 100%;
  max-height: 14.5em;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  object-fit: contain !important;
  background-color: #ffffff;
}

.event-card {
  min-height: 14.5em;
  padding: .8em 1em .8em 1.5em;
  overflow-wrap: break-word;
  overflow: hidden;
}

.event-card-name {
  font-size: 1.25rem;
  font-weight: 500;
  margin: .4em .26em;
}

.event-card-date {
  font-size: 1.07rem;
  margin: .4em .35em;
  color: #ffffff;
}

.event-card-city {
  font-size: 1.05rem;
  margin: .4em .35em;
  color: #6b6e7c;
}

.event-card-types {
  font-size: 1.02rem;
  margin: .4em .35em;
  color: #6b6e7c;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .event-card-box {
    height: 18em;
  }

  .event-img-box {
    height: 9em;
    width: 100%;
  }

  .event-img {
    padding: .2em 0em;
    border-top-left-radius: 23px !important;
    border-top-right-radius: 23px !important; 
  }

  .event-card-name {
    font-size: .95rem;
    font-weight: 500;
    margin: .1em .35em;
  }
  
  .event-card-date {
    font-size: .8rem;
    margin: .35em;
    color: #ffffff;
  }
  
  .event-card-city {
    font-size: .8rem;
    margin: .35em;
  }
  
  .event-card-types {
    font-size: .8rem;
    margin: .35em;
    color: #6b6e7c;
    overflow: hidden;
  }
}