.filter-bar {
  background-color: #222222;
  border: 1px solid #62d1de;
  border-radius: 5px;
}

.search-bar { 
  background-color: #222222 !important;
  padding: .1em .3em;
  display: flex;
  align-items: center;
  width: 12.5em;
  border-radius: 5px;
}

.MuiChip-root {
  background-color: #304949 !important;
  color: #62d1de !important;
  border: 1px solid #62d1de !important;
}

.date-picker {
  display: inline-block;
  width: 7.4em;
}

@media screen and (max-width: 480px) {
  .search-bar {
    padding: 0em .25em;
    display: flex;
    align-items: center;
    width: 100%;
    height: 2.9em;
    border: 1px solid #62d1de;
    border-radius: 5px !important;
  }
}